/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useMemo } from 'react';

import ArrowRightSvg from '@stories/atoms/Icons/arrow_right.svg';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';

export const arrowButtonSize = 2.75; // rem

export const glassArrowCss = {
  backgroundColor: 'hsl(0 0% 100% / 45%)',
  backdropFilter: 'blur(25px)',
  boxShadow: '0 0 3px hsl(0 0% 0% / 50%)',
  transition: 'opacity 250ms ease-in-out, transform 250ms ease-in-out',
  ':disabled': { opacity: 0, transform: 'scale(0.2) rotate(75deg)' }
};

const styleCssFn = (t: Theme) => ({
  solid: {
    backgroundColor: t.color.grey.g92,
    color: t.color.primary,
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        backgroundColor: t.color.hover.g92,
        color: t.color.hover.primary
      }
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: '0.4',
      pointerEvents: 'none'
    }
  },
  outline: {
    backgroundColor: 'transparent',
    border: `1px solid ${t.color.grey.g92}`,
    color: t.color.grey.g92,
    transition: 'opacity 250ms ease-in-out',
    ':hover': {
      backgroundColor: 'hsl(0 0% 100% / 5%)'
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: '0.4',
      pointerEvents: 'none'
    }
  }
});
export const ArrowButton: React.FC<{
  onClick?: () => void;
  cssProps?: Interpolation<Theme>;
  disabled?: boolean;
  outline?: boolean;
}> = ({ onClick, cssProps, disabled, outline }) => {
  const theme = useTheme();
  const arrowStyle = useMemo(() => styleCssFn(theme), [theme]);

  return (
    <button
      onClick={onClick}
      css={() => ({
        borderRadius: `${arrowButtonSize}rem`,
        lineHeight: `${arrowButtonSize}rem`,
        width: `${arrowButtonSize}rem`,
        ...(arrowStyle[outline ? 'outline' : 'solid'] as object),
        ...(cssProps as object)
      })}
      disabled={disabled}
      type="button"
    >
      <ArrowRightSvg
        css={() => ({
          display: 'block',
          height: `${arrowButtonSize}rem`,
          margin: 'auto'
        })}
      />
    </button>
  );
};

export default ArrowButton;
